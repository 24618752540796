html,
body,
#root,
#app-container {
    height: 100%;
}

#app-container {
    overflow: auto;
    background-color: black;
}

#navbar {
    height: 86px;
}

#land-view,
#manager-view,
#admin-view,
#profile-view {
    height: calc(100% - 86px);
    margin-bottom: 0;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@font-face {
    font-family: 'Reglisse fill';
    src: url('./fonts/Reglisse_Fill.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Reglisse fill", Roboto, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.clickable {
    cursor: pointer;
    transition: .25s;
}

.clickable.rounded {
    border-radius: 3px;
}

.clickable:hover {
    background-color: #ddd !important;
    color: black !important;
}

.clickable.selected:hover {
    color: #de5c89 !important;
}

rect.clickable:hover {
    fill: #ddd !important;
}

.clickable-dark:hover .Dropdown-placeholder {
    background-color: #aaa !important;
    color: white !important;
    cursor: pointer;
}

.clickable-dark:hover {
    background-color: #aaa !important;
    color: white !important;
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.t18-btn {
    background-color: #de5c89 !important;
    font-family: "Reglisse fill", Roboto, sans-serif;
}

.t18-btn.lm {
    margin-left: 10px !important;
}

.t18-btn.black {
    background-color: black !important;
}

.t18-btn.grey {
    background-color: grey !important;
}

.theme-color {
    color: #de5c89 !important;
}

.pink-t18-bg {
    background-color: #de5c89 !important;
}

#marketplace-title {
    color: white;
    display: inline-block;
    font-size: 60px;
    line-height: 60px;
    vertical-align: top;
    font-family: "Reglisse fill", Roboto, sans-serif;
    margin-right: 30px;
}

.navbar-menu-option {
    color: white;
    display: inline-block;
    vertical-align: top;
    font-family: "Reglisse fill", Roboto, sans-serif;
    line-height: 60px;
    margin: 0 15px;
    padding: 0 20px;
    font-size: 40px;
    cursor: pointer;
}

@media (max-width: 1500px) {
    #marketplace-title {
        font-size: 40px;
    }

    .navbar-menu-option {
        font-size: 30px;
    }
}

@media (max-width: 1150px) {
    #marketplace-title {
        font-size: 32px;
    }

    .navbar-menu-option {
        font-size: 24px;
    }
}

@media (max-width: 1020px) {
    #marketplace-title {
        font-size: 24px;
    }

    .navbar-menu-option {
        font-size: 16px;
    }
}

.navbar-menu-option.selected {
    background-color: #de5c89;
    border-radius: 10px;
}

#land-map-container {
    display: inline-block;
    border: 1px solid black;
    overflow: hidden;
}

#land-map * {
    box-sizing: initial;
}

#land-map-zoom-container {
    display: inline-block;
    position: relative;
    top: 20px;
    left: 20px;
    background-color: white;
    border-radius: 5px;
    vertical-align: top;
    z-index: 100;
}

.land-map-zoom-button {
    height: 25px;
    width: 25px;
}

#land-toolbox {
    font-family: "Reglisse fill", Roboto, sans-serif;
    color: white;
}

.loadingLayerWrapper {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

.loadingBox {
    background-color: white;
    padding: 2em;
}

.tool-tip {
    z-index: 10001 !important;
}

.reglisse {
    font-family: "Reglisse fill", Roboto, sans-serif;
}

.user-navbar-display {
    font-family: "Reglisse fill", Roboto, sans-serif;
    position: absolute;
    top: 0;
    right: 20px;
}

.center-align {
    text-align: center;
}

.inline-block {
    display: inline-block;
}

.lean-overlay.alert-popup-overlay {
    z-index: 4500 !important;
}

#media-form-content .check-box label span {
    font-size: 20px;
    color: black;
}

.input-field input[type=text]:focus,
.input-field input[type=number]:focus {
    border-bottom: 1px solid #de5c89 !important;
    box-shadow: 0 1px 0 0 #de5c89 !important;
}

.media-card,
.land-card,
.screen-card,
.template-card,
.subscription-card {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: white;
    border-radius: 15px;
    text-align: center;
    padding: 10px;
    overflow: hidden;
}

.land-card,
.template-card,
.subscription-card {
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.screen-card {
    height: 350px;
}

.media-card {
    height: 310px;
}

.media-card.selected,
.land-card.selected,
.template-card.selected,
.subscription-card.selected {
    border: 4px solid #de5c89;
}

.media-card-name,
.land-card-name,
.template-card-name,
.subscription-card-name,
.subscription-card-price {
    font-size: 20px;
    color: black;
}

.media-card-name {
    height: 60px;
    overflow: hidden;
    word-break: break-word;
}

.land-card, .subscription-card {
    height: 290px;
    position: relative;
}

.land-card-name {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
}

.media-toolbox-text {
    font-size: 24px;
    margin-left: 15px;
    margin-top: 20px;
}

#media-display-modal,
#display-media-add-modal,
#multi-screen-modal {
    max-height: 90%;
    top: 5% !important;
    width: 80%;
}

.utils-btn {
    font-size: 48px !important;
    line-height: 32px !important;
    padding: 0 10px !important;
}

.utils-btn.large {
    line-height: 48px !important;
}

#wcm-modal {
    z-index: 10000;
}

.modal-content label {
    font-size: 20px !important;
}

@media (max-width: 1500px) {
    .media-selector-action-btn {
        font-size: 38px !important;
        line-height: 38px !important;
    }
}

@media (max-width: 1300px) {
    .media-selector-action-btn {
        font-size: 32px !important;
        line-height: 32px !important;
    }
}

.folder-card .folder-card-icon {
    font-size: 160px;
    line-height: 160px;
}

.folder-card .folder-media-counter {
    position: absolute;
    left: calc(50% - 15px);
    color: white;
    width: 30px;
    height: 30px;
    font-size: 20px;
    border-radius: 50%;
}

@media (max-width: 1300px) {
    .folder-card .folder-card-icon {
        font-size: 120px;
        line-height: 120px;
    }
}

#chatWidget {
    z-index: 10000;
}

.h-100 {
    height: 100%;
    overflow: auto;
}

.lm {
    margin-left: 10px;
}

.lm-30 {
    margin-left: 30px;
}

.rm {
    margin-right: 10px;
}

.rm-30 {
    margin-right: 30px;
}

.tm {
    margin-top: 10px;
}

.tm-50 {
    margin-top: 50px;
}

.bm {
    margin-bottom: 10px;
}

#flash-sale-banner {
    color: #de5c89;
    display: inline-block;
    position: fixed;
    bottom: 20px;
    right: 40px;
    background-color: white;
    border: 3px solid #de5c89;
    border-radius: 15px;
    padding: 10px 20px;
    z-index: 100;
}

#flash-sale-banner.waiting-start {
    color: black;
}

.tag-badge {
    display: inline-block;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 5px;
    background-color: white;
}

.tag-badge.selected {
    background-color: #de5c89;
    color: white;
}

.transition-height {
    transition: max-height .5s linear;
}

.view-select-btn {
    font-family: "Reglisse fill", Roboto, sans-serif;
    font-size: 28px !important;
    border-radius: 5px !important;
    background-color: grey !important;
    line-height: 40px !important;
    height: 40px !important;
}

.view-select-btn.selected {
    background-color: #de5c89 !important;
}

.url-map-input .input-field {
    margin: 0;
}

.user-overlay {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 0;
    background-color: white;
    border-radius: 15px;
    z-index: 10000;
    padding: 20px;
    min-width: 280px;
}

.y-flip,
canvas {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}

.early-access-info-btn {
    background-color: #de5c89 !important;
    color: white;
    border-radius: 50%;
    font-size: 42px !important;
    margin-right: 15px;
    position: relative;
    top: 23px;
}

.progress {
    background-color: #ff80ab !important;
}

.progress .indeterminate {
    background-color: #de5c89 !important;
}

#navbar-user-button.selected {
    color: #de5c89 !important;
    border: 3px solid #de5c89;
    background-color: white;
}

.profile-view input {
    color: white;
}

.show-password-icon {
    position: absolute;
    right: 0;
    top: 10px;
    color: #838383;
    z-index: 2;
    font-size: 26px;
}